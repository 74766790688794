<template>
  <DetailTemplate
    :customClass="'service-detail detail-page'"
    v-if="getPermission('service:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1
            class="form-title custom-nowrap-ellipsis text-capitalize mb-0 mr-2"
          >
            {{ detail.name }}
          </h1>
          <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3"
            style="font-size: 21px !important; font-weight: 700"
            >{{ detail.barcode }}</v-chip
          >
          <v-chip
            v-if="detail.activated == 1"
            class="mx-2"
            color="#66bb6a"
            label
            outlined
            text-color="green"
          >
            Active
          </v-chip>
          <v-chip
            v-if="detail.activated == 0"
            class="mx-2"
            color="red"
            outlined
            text-color="red"
            label
          >
            In-Active
          </v-chip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('service:update')">
        <v-skeleton-loader
          class="custom-skeleton d-inline-block"
          v-if="pageLoading"
          type="button"
        >
        </v-skeleton-loader>
        <v-menu
          v-else
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in serviceMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-if="getPermission('service:update')">
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :to="
            getDefaultRoute('service.update', {
              param: { id: service },
            })
          "
        >
          <v-icon small left>mdi-pencil</v-icon>
          Edit
        </v-btn>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-tabs
          v-model="serviceTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              v-if="getPermission(tab.permission + ':view')"
              class="font-size-16 font-weight-600 px-8"
              :key="index"
              :href="'#' + tab.key"
              :disabled="tab.disabled"
            >
              <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                <!--begin::Svg Icon-->
                <inline-svg
                  :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                />
                <!--end::Svg Icon-->
              </span>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="serviceTab">
          <v-tab-item v-if="getPermission('service:view')" :value="'service'">
            <Overview :detail="detail"></Overview>
          </v-tab-item>
          <v-tab-item v-if="getPermission('service:view')" :value="'image'">
            <div class="service-detail-height">
              <Images
                :images="detail.product_images"
                :parent="detail.id"
                type="service"
                :isPageLoading="pageLoading"
              ></Images>
            </div>
          </v-tab-item>

          <v-tab-item v-if="getPermission('job:view')" :value="'job'">
            <div class="service-detail-search-height">
              <Tickets :detail="detail" type="service"></Tickets>
            </div>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('quotation:view')"
            :value="'quotation'"
          >
            <div class="service-detail-search-height">
              <Quotations :detail="detail" type="service"></Quotations>
            </div>
          </v-tab-item>
          <v-tab-item v-if="getPermission('invoice:view')" :value="'invoice'">
            <div class="service-detail-search-height">
              <Invoices :detail="detail" type="service"></Invoices>
            </div>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <div class="service-detail-search-height">
              <InternalHistoryDetail
                type="service"
                :type_id="detail.id"
              ></InternalHistoryDetail>
            </div>
          </v-tab-item>

          <v-tab-item value="attachment">
            <AdditionalDocuments
              :type-id="detail.id"
              class="mx-4"
              type-text="Service Documents"
              :type="10"
              :extensive="false"
            ></AdditionalDocuments>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </DetailTemplate>
</template>

<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CommonMixin from "@/core/plugins/common-mixin";

import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/service/detail/Overview";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mixins: [CommonMixin],
  data() {
    return {
      detail: new Object(),
      serviceTab: "service",
      serviceMoreAction: [
        {
          title: "Duplicate",
          action: "duplicate",
          icon: "mdi-content-duplicate",
          disabled: false,
        },
        {
          title: "Mark as Active",
          action: "mark_as_active",
          icon: "mdi-check-all",
          disabled: false,
        },
        {
          title: "Mark as In-Active",
          action: "mark_as_inactive",
          icon: "mdi-check-all",
          disabled: false,
        },
      ],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "service",
          permission: "service",
          disabled: false,
        },
        {
          title: "Images",
          icon: "mdi-phone",
          key: "image",
          permission: "product",
          disabled: false,
        },
        {
          title: "Attachments",
          icon: "mdi-account-multiple",
          key: "attachment",
          permission: "product",
          disabled: false,
        },
        /*{
          title: "Jobs",
          icon: "mdi-credit-card",
          key: "job",
          permission: "job",
          disabled: false,
        },
        {
          title: "Quotations",
          icon: "mdi-account-multiple",
          key: "quotation",
          permission: "quotation",
          disabled: false,
        },
        {
          title: "Invoices",
          icon: "mdi-account-multiple",
          key: "invoice",
          permission: "invoice",
          disabled: false,
        },*/
        {
          title: "History",
          icon: "mdi-account-multiple",
          key: "history",
          permission: "history",
          disabled: false,
        },
      ],
      formValid: true,
      formLoading: false,
      pageLoading: false,
      service: 0,
    };
  },
  components: {
    DetailTemplate,
    Images,
    Overview,
    Tickets,
    Quotations,
    Invoices,
    InternalHistoryDetail,
    AdditionalDocuments,
  },
  methods: {
    patchProductParams(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: [_this.service],
          },
        })
        .then(() => {
          _this.getProductDetail();
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.duplicateProduct();
          break;
        case "mark_as_active":
          _this.patchProductParams(1);
          break;
        case "mark_as_inactive":
          _this.patchProductParams(0);
          break;
      }
    },
    duplicateProduct() {
      this.$router.push(
        this.getDefaultRoute("service.create", {
          query: {
            duplicate: this.service,
          },
        })
      );
    },
    getProductDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "service/" + _this.service,
        })
        .then(({ data }) => {
          _this.detail = data;

          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Service", route: "service" },
            { title: "Detail" },
            { barcode: data.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getProductDetail();

    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service", route: "service" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.service = _this.$route.params.id;
    if (!_this.service || _this.service <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.serviceTab = _this.$route.query.tab;
    }
  },
  computed: {
    // serviceTab: {
    //   set(val) {
    //     let query = { ...this.$route.query };
    //     query.tab = val;
    //     if (this.serviceTab != val) {
    //       this.$router.push({ query });
    //     }
    //   },
    //   get() {
    //     return this.$route.query.tab || "service";
    //   },
    // },
  },
  // beforeDestroy() {
  // 	this.$store.dispatch(SET_BREADCRUMB, []);
  // },
};
</script>

<style>
#attachment .overflow-y {
  max-height: none !important;
}
</style>
